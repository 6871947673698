import "./src/styles/global.css"

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import CSSRulePlugin from "gsap/CSSRulePlugin"
import CSSPlugin from "gsap/CSSPlugin"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger, CSSPlugin, CSSRulePlugin)
}

const resetScrollOnRefresh = () => {
  window.onbeforeunload = function () {
    document.body.style.visibility = "hidden"
    window.scrollTo(0, 0)
  }
}
export const onRouteUpdate = ({ location, prevLocation }) => {
  //console.log("new pathname", location.pathname)
  //console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  window.scrollTo(0, 0)
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  //const currentPosition = getSavedScrollPosition(location)
  //const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

  window.scrollTo(0, 0)

  return false
}

export const onClientEntry = () => {
  window.onload = () => {
    window.scrollTo(0, 0)
    document.body.style.scrollBehavior = "smooth"
  }
  resetScrollOnRefresh()
}

export { wrapRootElement, wrapPageElement } from "./gatsby-ssr"
