import React from "react"
import { I18nextProvider } from "react-i18next"
import { PageContextProvider } from "./src/providers/pageContext"
import i18n from "./src/services/i18n"

export function onRenderBody({ setHeadComponents }) {
  if (process.env.NODE_ENV === "development") return

  setHeadComponents([
    <script 
      type="text/javascript" 
      src="https://cookie-cdn.cookiepro.com/consent/f86cd051-1e77-4987-9acd-faf4bdd791a5/OtAutoBlock.js" 
    ></script>,
    <script 
      src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js" 
      data-document-language="true" 
      type="text/javascript" 
      charset="UTF-8" 
      data-domain-script="f86cd051-1e77-4987-9acd-faf4bdd791a5" 
    ></script>,
    <script
      key="cookiebot-callback"
      dangerouslySetInnerHTML={{
        __html: `function OptanonWrapper() {
          window.scrollTo(0, 0)
        }`,
      }}
    />,
  ])
}

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider pageContext={props.pageContext}>
      {element}
    </PageContextProvider>
  )
}
