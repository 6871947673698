const defaultLanguage = "pt"

const linkResolver = (page, lang, originalPath = "") => {
  if (lang === defaultLanguage)
    return page ? `/${page.slugs[lang]}/` : originalPath

  return page ? `/${lang}/${page.slugs[lang]}/` : `/${lang}${originalPath}`
}

module.exports = linkResolver
