import linkResolver from "../utils/linkResolver"
import { navigate } from "gatsby"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

const PageContext = React.createContext({})

export const PageContextProvider = ({ pageContext, children }) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    console.log(`changing language to ${pageContext.lang}`)
    i18n.changeLanguage(pageContext.lang)
  }, [pageContext.lang])

  return (
    <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
  )
}

export const useLanguageSwitch = () => {
  const {
    localizedRoutes,
    originalPath,
    lang: currentLang,
    supportedLanguages,
  } = React.useContext(PageContext)

  const switchLanguage = lang => {
    if (lang === currentLang) return

    if (
      !supportedLanguages.find(supportedLanguage => supportedLanguage === lang)
    ) {
      console.log(`Language ${lang} is not a supported language.`)
      return
    }

    console.log("originalPath ", originalPath)

    if (!localizedRoutes[lang]) {
      return navigate(linkResolver(undefined, lang, originalPath))

      /*console.error(
        `Language ${lang} does not existe in the localizedRoutes of page ${originalPath}`
      )
      return */
    }

    navigate(`${localizedRoutes[lang]}`)
  }

  return switchLanguage
}

export const usePageContext = () => React.useContext(PageContext)
